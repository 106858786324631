import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  Center,
  Flex,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { RiBankCardLine, RiFileCopy2Fill, RiHistoryFill } from "react-icons/ri";
import { Link } from "react-router-dom";
import PageHeader from "../../components/PageHeader";

const EsteghfarBookOrderHemayat = () => {
  const toast = useToast();

  const handleCopyToClipboard = async (
    itemToCopy: string,
    itemName: string
  ) => {
    if (navigator.clipboard) {
      await navigator.clipboard.writeText(itemToCopy);
      toast({
        status: "success",
        title: `${itemName} با موفقیت در کلیپبورد ذخیره شد!`,
      });
    } else {
      toast({
        status: "error",
        title: "متاسفانه، امکان کپی وجود ندارد.",
      });
    }
  };
  return (
    <Flex direction="column" gap={30}>
      <PageHeader
        title='حمایت از طرح بزرگ استغفار'
        drawerTitle="راهنمای پرداخت"
        hasCard
        backTo='/esbook'
      >
        <Flex flexDirection="column" alignItems="stretch" gap="1rem">
          <Text textAlign="justify">
            حمایت از طرح بزرگ استغفار
          </Text>
        </Flex>
      </PageHeader>
      <Box>
        <Box >
          <Card h="100%" className="rising_card" variant="outline">
            <CardBody as={Center} pt="1.5rem">
              <Text fontSize="md" fontWeight="bold" textAlign="center">
                نذر ظهور امام زمان (عجل الله تعالی فرجه) هرچقدر در توانت هست از فرهنگ استغفار حمایت کن
              </Text>
            </CardBody>

            <CardFooter
              as={VStack}
              spacing=".75rem"
              alignItems="stretch"
              pt=".5rem"
            >
              <Button
                as={Link}
                to="/p/EsteghfarBookOrder"
                leftIcon={<RiBankCardLine />} colorScheme="green">
                پرداخت
              </Button>

              <Button
                leftIcon={<RiHistoryFill />}
                colorScheme="gray"
                variant="outline"
                as={Link}
                to="/p/h/EsteghfarBookOrder"
              >
                تاریخچه پرداخت ها
              </Button>
            </CardFooter>
          </Card>
        </Box>
      </Box>
      <Card padding='20px 5px' marginBottom={20} variant="outline">
        <Text align="center" fontWeight="bold" marginBottom={5}>
          اطلاعات حساب جهت واریز نقدی به نام کانون حضرت مهدی عجل الله
        </Text>
        <Box>
          <Text align="center" mb={4}>
            شماره کارت:
          </Text>
          <Flex
            justify="center"
            align="center"
            gap={1}
            onClick={() =>
              handleCopyToClipboard("5029087001440554", "شماره کارت")
            }
          >
            <Flex
              border="1px solid gray"
              borderRadius={6}
              padding={1}
              fontSize="0.7rem"
            >
              <RiFileCopy2Fill style={{ fontSize: 20 }} />
              کپی شماره
            </Flex>
            <Text align="center">5029-0870-0144-0554</Text>
          </Flex>
        </Box>
        <Box>
          <Text align="center" mb={4}>
            شماره حساب
          </Text>
          <Flex
            justify="center"
            align="center"
            gap={1}
            onClick={() => handleCopyToClipboard("190030661855021", "شماره حساب")}
          >
            <Flex
              border="1px solid gray"
              borderRadius={6}
              padding={1}
              fontSize="0.7rem"
            >
              <RiFileCopy2Fill style={{ fontSize: 20 }} />
              کپی شماره
            </Flex>
            <Text align="center">1900.306.6185502.1</Text>
          </Flex>
        </Box>
        <Box>
          <Text align="center" mb={4}>
            شماره شبا
          </Text>
          <Flex
            justify="center"
            align="center"
            gap={1}
            onClick={() =>
              handleCopyToClipboard("520220190003066185502001", "شماره شبا")
            }
          >
            <Flex
              border="1px solid gray"
              borderRadius={6}
              padding={1}
              fontSize="0.7rem"
            >
              <RiFileCopy2Fill style={{ fontSize: 20 }} />
              کپی شماره
            </Flex>
            <Text align="center">IR520220190003066185502001</Text>
          </Flex>
        </Box>
      </Card>

    </Flex>
  );
};

export default EsteghfarBookOrderHemayat;
