import { createContext, useContext } from "react";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import useDefaultToast from "./useDefaultToast";
import axios from "axios";
import { HIDE_REGISTER_ADDRESS_ALERT_KEY } from "../components/RegisterAddressAlert";
import useIVP from "./IVP";

export const USER_AVATAR_KEY = "USER_AVATAR";
export const USER_PROFILE_INFO_KEY = "UserProfileInfo";

export const BASE_URL = process.env.REACT_APP_BASE_URL;

export const AuthContext = createContext({});

/** Extracting information from cookies */
export function loadAuth() {
  const token = Cookies.get("token");
  const sid = Cookies.get("sid");
  return { token, sid, BASE_URL };
}

export function loadUserAvatar() {
  return localStorage.getItem(USER_AVATAR_KEY);
}

// Function to generate a unique code
function generateUniqueCode() {
  const timestamp = new Date().getTime(); // Get current timestamp
  const randomNum = Math.floor(Math.random() * 1000); // Generate a random number

  // Concatenate timestamp and random number
  const uniqueCode = `${timestamp}-${randomNum}`;

  // Hash the unique code for added security
  const hashedCode = hashFunction(uniqueCode);

  return hashedCode;
}

// Hashing function (you can use libraries like CryptoJS for more secure hashing)
function hashFunction(data) {
  let hash = 0;
  if (data.length == 0) {
    return hash;
  }
  for (let i = 0; i < data.length; i++) {
    const char = data.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash; // Convert to 32bit integer
  }
  return hash;
}

/** Authentication hook */
export function useAuth() {
  const navigate = useNavigate();
  // const { setIVP } = useIVP();

  const showToast = useDefaultToast();
  const {
    auth,
    isAuthenticated,
    avatar,
    isVip,
    setAuth,
    setAuthStatus,
    setAvatar,
    setVip,
  } = useContext(AuthContext);

  /** Checks whether the context is added to the DOM or not */
  if (!auth || !setAuth) {
    throw new Error(
      "Auth token is unavailable, Did you add <AuthContext.Provider> to the DOM?"
    );
  }

  /** Saving token and sid in cookies */
  function signIn(token, sid) {
    Cookies.set("token", token, { expires: 365 });
    Cookies.set("sid", sid, { expires: 365 });
    setAuth({ token, sid, BASE_URL });
    setAuthStatus(true);
  }

  function setUserAvatar(avatar) {
    if (avatar) {
      localStorage.setItem(USER_AVATAR_KEY, avatar);
      setAvatar(avatar);
    } else {
      localStorage.removeItem(USER_AVATAR_KEY);
      setAvatar(null);
    }
  }

  function getUserInfo() {
    const userInfo = Cookies.get(USER_PROFILE_INFO_KEY);
    if (userInfo) {
      return JSON.parse(userInfo);
    }

    return {};
  }

  function makeUserVip() {
    // setIVP();
    setVip(true);
  }

  /** Delete all saved user information */
  function signOut() {
    Cookies.remove("token");
    Cookies.remove("sid");
    Cookies.remove("ipc");
    Cookies.remove("ips");
    Cookies.remove("ivp");
    Cookies.remove("UserProfileInfo");
    Cookies.remove("isDeviceTokenSaved");
    Cookies.remove(HIDE_REGISTER_ADDRESS_ALERT_KEY);
    setUserAvatar(null);
    setAuth({ token: undefined, sid: undefined, BASE_URL });
    setAuthStatus(false);
  }

  function checkForInvalidToken(error) {
    if (error.response) {
      if (error.response.data.detail === "Invalid token!") {
        signOut();

        //Check token expire
        if (auth.token) {
          showToast({
            // title: "متاسفانه، اطلاعات ورود شما منقضی شده است!",
            title:
              "برای استفاده از این قسمت باید به حساب کاربری خود وارد شوید.",
            description: "لطفاً ‌دوباره وارد حساب خود شوید.",
            status: "error",
            id: "TOKEN_EXPIRE", //prevent dupplicate
          });
        } else {
          showToast({
            title: "ورود به حساب الزامی است!",
            description:
              "برای دسترسی به امکانات اپلیکیشن باید وارد حساب خود شوید.",
            status: "error",
            id: "TOKEN_NEED", //prevent dupplicate
          });
        }

        navigate("/signin");
        return true;
      }
    }

    return false;
  }

  let uniqueBrowserCode = generateUniqueCode();

  if (window.localStorage["agentId"]) {
    uniqueBrowserCode = window.localStorage["agentId"];
  } else {
    window.localStorage["agentId"] = uniqueBrowserCode = generateUniqueCode();
  }
  //create instance of axios with token
  const authorizedAxios = axios.create({
    baseURL: BASE_URL,
    headers: auth.token
      ? { Authorization: `Bearer ${auth.token}`, "Agent-Id": uniqueBrowserCode }
      : { "Agent-Id": uniqueBrowserCode },
  });

  return {
    token: auth.token,
    sid: auth.sid,
    userAvatar: avatar,
    BASE_URL,
    isAuthenticated,
    isVip,
    getUserInfo,
    signIn,
    signOut,
    setUserAvatar,
    makeUserVip,
    checkForInvalidToken,
    authorizedAxios,
    agentId: uniqueBrowserCode,
  };
}
