import { Link } from "react-router-dom";
import "./index.css";
import { Box, Button, useColorMode } from "@chakra-ui/react";

const Ad = () => {
  const colorMode = useColorMode();
  return (
    // <div className="header-add-container">
    //   <Link className="header-add" to="/f">
    //     هم اکنون به جمع خانواده با برکت شمیم بپیوندید
    //   </Link>
    // </div>
    <div
      style={{
        backgroundImage: "url(/images/islamic-ornament-persian-01.jpg)",
        width: "100%",
        backgroundSize: "cover",
        padding: "7px",
        position: "sticky",
        top: 0,
        zIndex: 9,
      }}
    >
      <Link to="/p/farimaneh-tkh">
        <Box
          style={{
            padding: "10px",
            borderRadius: "5px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          bg={
            colorMode.colorMode === "light"
              ? "rgba(255, 255, 255, 0.9)"
              : "rgb(26 32 44 / 90%)"
          }
        >
          <div style={{ width: "80%" }}>
            <strong>
              {/* <h1>😳 پاداشی که نه چشمی دیده نه گوشی شنیده</h1> */}
              <h1 style={{ fontSize: "1.2rem" }}>
                ✨ در ثواب هدایا این چله شریک شوید ✨
              </h1>
              <strong> چله زیارتی تحکیم خانواده - استاد فریمانه </strong>
            </strong>
          </div>
          <div style={{ width: "23%" }}>
            <Button variant="solid" colorScheme="red" width="100%">
              حمایت میکنم
            </Button>
          </div>
        </Box>
      </Link>
    </div>
  );
};

export default Ad;

