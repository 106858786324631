import { lazy } from "react";
import { createBrowserRouter } from "react-router-dom";
import MainLayout from "../layouts/Main";
import ProtectedRoute from "../components/ProtectedRoute";
import { wait } from "../utils/wait";
import CountEventDetail from "../pages/Dashboard/Events/Details/CountEventDetail";
import QuranEventDetail from "../pages/Dashboard/Events/Details/QuranEventDetail";
import PayVip from "../pages/IslamicPayment/PayVip";
import ErrorPage from "../pages/Error";
import Landing from "../pages/Landing";
import EsteghfarBookOrderHemayat from "../pages/EsteghfarBookOrderHemayat";
import EsteghfarBookOrderPresent from "../pages/EsteghfarBookOrderPresent";
import ManualPayments from "../pages/IslamicPayment/ManualPayments";

const LAYZY_IMPORT_DELAY = 0;

const ProfileInfo = lazy(() =>
  wait(LAYZY_IMPORT_DELAY).then(() => import("../pages/Dashboard/Profile/Info"))
);

const ShamimFamilyPage = lazy(() =>
  wait(LAYZY_IMPORT_DELAY).then(() =>
    import("../pages/ShamimFamily/ShamimFamilyForm")
  )
);
const ProfileChangePassword = lazy(() =>
  wait(LAYZY_IMPORT_DELAY).then(() =>
    import("../pages/Dashboard/Profile/ChangePassword")
  )
);

const HomePage = lazy(() =>
  wait(LAYZY_IMPORT_DELAY).then(() => import("../pages/Home"))
);
const SignInPage = lazy(() =>
  wait(LAYZY_IMPORT_DELAY).then(() => import("../pages/SignIn"))
);
const NotFoundPage = lazy(() =>
  wait(LAYZY_IMPORT_DELAY).then(() => import("../pages/404"))
);

const CitiesPage = lazy(() =>
  wait(LAYZY_IMPORT_DELAY).then(() => import("../pages/Cities"))
);

const ChannelsPage = lazy(() =>
  wait(LAYZY_IMPORT_DELAY).then(() => import("../pages/ChannelsPage.jsx"))
);
const ContactPage = lazy(() =>
  wait(LAYZY_IMPORT_DELAY).then(() => import("../pages/Contact"))
);
const SupportPage = lazy(() =>
  wait(LAYZY_IMPORT_DELAY).then(() => import("../pages/Support"))
);

const ProfilePage = lazy(() =>
  wait(LAYZY_IMPORT_DELAY).then(() => import("../pages/Dashboard/Profile"))
);
const PetitionPage = lazy(() =>
  wait(LAYZY_IMPORT_DELAY).then(() => import("../pages/Dashboard/Petition"))
);

const EventsPage = lazy(() =>
  wait(LAYZY_IMPORT_DELAY).then(() => import("../pages/Dashboard/Events"))
);
const EventDetailsPage = lazy(() =>
  wait(LAYZY_IMPORT_DELAY).then(() =>
    import("../pages/Dashboard/Events/Details")
  )
);

const IslamicPayment = lazy(() =>
  wait(LAYZY_IMPORT_DELAY).then(() => import("../pages/IslamicPayment"))
);
const FetriehGandomAam = lazy(() =>
  wait(LAYZY_IMPORT_DELAY).then(() =>
    import("../pages/IslamicPayment/FetriehGandomAam")
  )
);
const FetriehBrenjAam = lazy(() =>
  wait(LAYZY_IMPORT_DELAY).then(() =>
    import("../pages/IslamicPayment/FetriehBrenjAam")
  )
);
const FetriehSadatGandom = lazy(() =>
  wait(LAYZY_IMPORT_DELAY).then(() =>
    import("../pages/IslamicPayment/FetriehSadatGandom")
  )
);
const FetriehSadatBrenj = lazy(() =>
  wait(LAYZY_IMPORT_DELAY).then(() =>
    import("../pages/IslamicPayment/FetriehSadatBrenj")
  )
);
const KaffarehTakhir = lazy(() =>
  wait(LAYZY_IMPORT_DELAY).then(() =>
    import("../pages/IslamicPayment/KaffarehTakhir")
  )
);
const KaffarehAmd = lazy(() =>
  wait(LAYZY_IMPORT_DELAY).then(() =>
    import("../pages/IslamicPayment/KaffarehAmd")
  )
);
const Nazr = lazy(() =>
  wait(LAYZY_IMPORT_DELAY).then(() => import("../pages/IslamicPayment/Nazr"))
);
const Ghorbani = lazy(() =>
  wait(LAYZY_IMPORT_DELAY).then(() =>
    import("../pages/IslamicPayment/Ghorbani")
  )
);
const Sadagheh = lazy(() =>
  wait(LAYZY_IMPORT_DELAY).then(() =>
    import("../pages/IslamicPayment/Sadagheh")
  )
);
const AllPaymentsHistoryPage = lazy(() =>
  wait(LAYZY_IMPORT_DELAY).then(() =>
    import("../pages/IslamicPayment/AllPaymentsHistory")
  )
);
const PaymentReceiptPage = lazy(() =>
  wait(LAYZY_IMPORT_DELAY).then(() =>
    import("../pages/IslamicPayment/AllPaymentsHistory/PaymentReceipt")
  )
);
const PaymentRePayShamimPage = lazy(() =>
  wait(LAYZY_IMPORT_DELAY).then(() =>
    import("../pages/IslamicPayment/AllPaymentsHistory/PaymentRePayShamimPage")
  )
);
const BillPaymentsHistoryPage = lazy(() =>
  wait(LAYZY_IMPORT_DELAY).then(() =>
    import("../pages/IslamicPayment/AllPaymentsHistory/BillPaymentsHistory")
  )
);

const EsteghfarBookOrderLanding = lazy(() =>
  wait(LAYZY_IMPORT_DELAY).then(() =>
    import("../pages/EsteghfarBookOrderLanding")
  )
);

/** App router configuration */
const AppRouter = createBrowserRouter([
  {
    path: "/",
    element: <MainLayout />,
    // errorElement: <ErrorPage />,
    ErrorBoundary: ErrorPage,
    children: [
      // :: Home page
      {
        index: true,
        element: <HomePage />,
      },
      // :: SignIn page
      {
        path: "signin",
        element: <SignInPage />,
      },
      // :: Cities page
      {
        path: "cities",
        element: <CitiesPage />,
      },
      {
        path: "channels",
        element: <ChannelsPage />,
      },
      // :: Contact page
      {
        path: "contact",
        element: <ContactPage />,
      },
      // :: EsteghfarBookOrder page
      {
        path: "esbook",
        element: <EsteghfarBookOrderLanding />,
      },
      {
        path: "esh",
        element: <EsteghfarBookOrderHemayat />,
      },
      {
        path: "es",
        element: <EsteghfarBookOrderPresent />,
      },
      // :: Support page
      {
        path: "support",
        element: <ProtectedRoute element={<SupportPage />} />,
      },

      //خانواده شمیم
      {
        path: "f",
        element: <ProtectedRoute element={<ShamimFamilyPage />} />,
      },
      // -------------------------- NEW
      {
        path: "u/p",
        index: false,
        element: (
          <ProtectedRoute
            element={<ProfilePage />}
            checkProfile={false}
            checkPassword={false}
          />
        ),
        children: [
          {
            index: true,
            element: <ProfileInfo />,
          },
          {
            path: "change-password",
            index: false,
            element: <ProfileChangePassword />,
          },
        ],
      },
      // :: Petition page
      {
        path: "u/arizeh",
        index: false,
        element: <ProtectedRoute element={<PetitionPage />} />,
      },

      {
        path: "intro-land",
        index: false,
        element: <Landing />,
      },
      {
        path: "q/:eventId",
        index: false,
        element: <QuranEventDetail />,
      },
      {
        path: "q",
        index: false,
        element: <QuranEventDetail />,
      },

      {
        path: "u/e/",
        index: false,
        children: [
          {
            index: true,
            element: <ProtectedRoute element={<EventsPage />} />,
          },
          {
            path: "c/:eventId",
            index: false,
            element: <CountEventDetail />,
          },
          {
            path: "q/:eventId",
            index: false,
            element: <QuranEventDetail />,
          },
          {
            path: ":eventId",
            index: false,
            element: <EventDetailsPage />,
          },
        ],
      },

      {
        path: "/p",
        index: false,
        children: [
          {
            index: true,
            element: <ProtectedRoute element={<IslamicPayment />} />,
          },
          {
            path: "/p/f",
            index: false,
            element: <ProtectedRoute element={<PayVip />} />,
          },
          {
            path: "/p/h/all",
            index: false,
            element: <ProtectedRoute element={<AllPaymentsHistoryPage />} />,
          },
          {
            path: "/p/h/:billId",
            index: false,
            element: <ProtectedRoute element={<BillPaymentsHistoryPage />} />,
          },
          {
            path: "/p/r/:paymentId",
            index: false,
            element: <ProtectedRoute element={<PaymentReceiptPage />} />,
          },
          {
            path: "/p/s/:liveState",
            index: false,
            element: <ProtectedRoute element={<PaymentRePayShamimPage />} />,
          },
          {
            path: "/p/:subject",
            index: false,
            children: [
              {
                index: true,
                element: <ProtectedRoute element={<PayVip />} />,
              },
              {
                path: "mp",
                index: false,
                element: <ManualPayments />,
              },
            ],
          },
        ],
      },

      {
        path: "/islamic-payment",
        index: false,
        children: [
          {
            index: true,
            element: <ProtectedRoute element={<IslamicPayment />} />,
          },
          {
            path: "/islamic-payment/fetrieh-g-a",
            index: false,
            element: <ProtectedRoute element={<FetriehGandomAam />} />,
          },
          {
            path: "/islamic-payment/fetrieh-b-a",
            index: false,
            element: <ProtectedRoute element={<FetriehBrenjAam />} />,
          },
          {
            path: "/islamic-payment/fetrieh-g-s",
            index: false,
            element: <ProtectedRoute element={<FetriehSadatGandom />} />,
          },
          {
            path: "/islamic-payment/fetrieh-b-s",
            index: false,
            element: <ProtectedRoute element={<FetriehSadatBrenj />} />,
          },
          {
            path: "/islamic-payment/kaffareh-t",
            index: false,
            element: <ProtectedRoute element={<KaffarehTakhir />} />,
          },
          {
            path: "/islamic-payment/kaffareh-a",
            index: false,
            element: <ProtectedRoute element={<KaffarehAmd />} />,
          },
          {
            path: "/islamic-payment/nazr",
            index: false,
            element: <ProtectedRoute element={<Nazr />} />,
          },
          {
            path: "/islamic-payment/sadagheh",
            index: false,
            element: <ProtectedRoute element={<Sadagheh />} />,
          },
          {
            path: "/islamic-payment/ghorbani",
            index: false,
            element: <ProtectedRoute element={<Ghorbani />} />,
          },
        ],
      },
      {
        path: "*",
        element: <NotFoundPage />,
      },
    ],
  },
]);

export default AppRouter;
