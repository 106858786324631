import { useParams, useSearchParams } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import { useAuth } from "../../../../hooks/useAuthContext";
import { AxiosError } from "axios";
import JoinEventButton from "../../../../components/JoinEventButton";
import PageHeader from "../../../../components/PageHeader";
import { parseEventData } from "../../../../utils/date";
import {
  ScaleFade,
  Flex,
  Text,
  Heading,
  Button,
  Box,
  Skeleton,
  Tag,
  Divider,
  Stack,
  Card,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import CommentsList from "../../../../components/CommentsList";
import useDefaultToast from "../../../../hooks/useDefaultToast";
import useEventStore, { EventTimeInfo } from "../../../../hooks/useEventStore";
import CountEventHeader from "../../../../components/EventDatesListHeader/CountEventHeader";
import NumberInputPicker from "../../../../components/CustomNumberInput/NumberInputPicker";
import ShareEventCard from "../../../../components/ShareEventCard";

export default function CountEventDetail() {
  const { eventId } = useParams();
  const { authorizedAxios, checkForInvalidToken } = useAuth();
  const showToast = useDefaultToast();
  const [searchParams] = useSearchParams();
  const [count, setCount] = useState(0);
  const [customCount, setCustomCount] = useState(0);
  const eventStore = useEventStore();
  const [showCustomInput, setShowCustomInput] = useState(false);
  const [customCountHasError, setCustomCountErrorState] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);

  const audioRef = useRef<HTMLAudioElement>(null);

  /** Manages get event information from the server */
  const getEventQuery = useQuery({
    queryKey: ["GetEventQuery", { eventId }],
    staleTime: Infinity,
    cacheTime: 0,
    retry: 0,
    queryFn: async () => {
      const [
        eventInfoRes,
        eventParticipationsRes,
        currentTimeRes,
        participantsRes,
      ] = await Promise.all([
        authorizedAxios.get(`/count_based_event/${eventId}/`),
        authorizedAxios.get(`/c_participation/user/${eventId}/`),
        authorizedAxios.get(`/time/`),
        authorizedAxios.get(`/c_participation/stat/${eventId}/`),
      ]);

      const isPublicEvent =
        eventInfoRes.data.data.publication_state === "public";

      return {
        time: currentTimeRes.data.data,
        isPublicEvent,
        participants: participantsRes.data.data,
        event: {
          ...eventInfoRes.data.data,
          ...eventParticipationsRes.data.data,
        },
      };
    },
    onError: (e) => {
      if (checkForInvalidToken(e)) return;
      //unhandled error
      showToast({
        title: "در دریافت پویش خطایی روی داد!",
        description:
          "باعرض پوزش در دریافت پویش خطایی روی داد از اینکه شکیبایی می کنید متشکریم.",
        id: "GetEventQuery_UNHANDLED_ERROR",
        status: "error",
      });
    },
  });

  const participationMutation = useMutation({
    mutationKey: ["participationMutation"],
    retry: 0,
    mutationFn: async (addCount: number) => {
      setShowCustomInput(false);
      setCustomCount(0);
      setIsPlaying(true);
      const res = await authorizedAxios.patch(`/c_participation/${eventId}/`, {
        count: addCount,
      });

      return res?.data;
    },
    onSuccess: () => {
      // queryClient.invalidateQueries(["GetEventQuery", { eventId }]);
      // changeCount(addCount);

      showToast({
        title: "شمارش  شما ثبت شد 😊",
        status: "success",
        id: "countAdded",
      });
    },
    onError: (e: AxiosError<any>) => {
      if (checkForInvalidToken(e)) return;

      if (e?.response?.data?.message === "already done!") {
        showToast({
          title: "شمارش  شما قبلا ثبت شده است! 😊",
          status: "success",
          id: "countBeforeAdded",
        });
      } else {
        showToast({
          title: "خطایی رخ داد",
          description: "لطفا با پشتیبانی در تماس باشید",
          status: "error",
          id: "countAddedError",
        });
      }
    },
  });

  const paymentStatus = searchParams.get("ps");
  useEffect(() => {
    if (paymentStatus) {
      if (paymentStatus === "200") {
        showToast({
          title: "با سپاس فراوان، پرداخت با موفقیت انجام شد!",
          description: "اکنون شما یکی از حامیان خادمان امام زمان (عج) هستید.",
          status: "success",
          id: "paymentStatusSuccess",
        });
      } else {
        showToast({
          title: "متاسفانه، پرداخت با موفقیت انجام نشد!",
          description: "در صورت تمایل می توانید دوباره اقدام کنید.",
          status: "error",
          id: "paymentStatusError",
        });
      }
    }
  }, [paymentStatus]);

  let eventInfo: EventTimeInfo | null = null;
  let eventTitle = "";
  if (getEventQuery.isSuccess) {
    const event = getEventQuery.data.event;
    eventTitle = event.name || "";
    eventInfo = parseEventData(event, getEventQuery.data.time);
  }

  const participants =
    participationMutation?.data?.data || getEventQuery.data?.participants;

  const onButtonClick = () => {
    participationMutation.mutate(defaultCount);
  };

  const target = eventInfo?.eventData.target || 0;

  const notStart = (eventInfo?.untilStart ?? 1) > 0;

  const defaultCount = eventInfo?.eventData.default_count || 1;

  const isPublic = getEventQuery.data?.isPublicEvent || false;

  let {
    participated_users_count,
    total_compensations_count,
    total_participations_count,
    user_participations_count,
    user_compensations_count,
  } = participants || {};
  const counted = user_participations_count + user_compensations_count;

  const isFinish =
    !(getEventQuery.isLoading || getEventQuery.isFetching) && counted >= target;

  const handleOutsideClick = (e) => {
    if (e.target.className.includes("modal-container")) {
      setShowCustomInput(false);
    }
  };

  const playAudio = () => {
    if (audioRef.current) {
      try {
        audioRef.current.play();
      } catch {
        setIsPlaying(false);
      }
    }
  };

  useEffect(() => {
    if (audioRef.current && isPlaying) {
      playAudio();
    }
  }, [isPlaying]);

  return (
    <ScaleFade in={true}>
      <PageHeader
        //@ts-ignore
        title={
          <>
            عنوان: {eventTitle}{" "}
            {isFinish && (
              <Tag mr={1} colorScheme="green">
                انجام شده
              </Tag>
            )}
          </>
        }
        isLoading={!eventInfo}
        drawerTitle="راهنمای صفحه پویش"
        hasCard
      >
        <Flex flexDirection="column" alignItems="stretch" gap="1rem">
          <Text textAlign="justify">
            در این بخش می توانید در کنار سایر خادمان امام زمان (عج)، در پویش های
            عمومی یا خصوصی شرکت کنید. دقت داشته باشید که پس از ایجاد پویش در
            اپلیکیشن خادمان، باید به صورت روزانه و در ساعات مشخص حضور خود را در
            اپلیکیشن به ثبت برسانید تا امتیاز خود را برای شرکت در قرعه کشی های
            خادمان افزایش دهید.
          </Text>

          <Heading fontSize="lg" mt=".5rem">
            ثبت حضور روزانه
          </Heading>
          <Text textAlign="justify">
            با کلیک روی دکمه بزرگ سبز رنگ، می توانید فرآیند ثبت حضور را انجام
            دهید. این فرآیند به صورت خودکار و با کلیک روی این دکمه انجام خواهد
            شد و نیاز به انجام هیچ کار دیگری از سمت شما وجود ندارد.{" "}
            <strong style={{ color: "var(--chakra-colors-blue-500)" }}>
              دقت داشته باشید که ثبت حضور فقط یکبار در طول روز قابل انجام است.
            </strong>{" "}
            پس از کلیک روی این دکمه، در صورت موفقیت آمیز بودن یا نبودن نتیجه
            فرآیند، یک پیغام به شما نمایش داده خواهد شد.
          </Text>
          <Text textAlign="justify">
            درصورت موفق بودن فرآیند، نمودار حضور شما در پویش به نمایش در می آید.
            همچنین اگر مرورگر شما از قابلیت دریافت اعلانات پشتیبانی کند، اجازه
            دریافت اعلانات از شما درخواست می شود که در صورت تمایل می توانید این
            اجازه را صادر بفرمایید. اگر این درخواست برای شما نمایش داده نشد ممکن
            است شما قبلا این اجازه را صادر کرده اید یا اینکه به دلیل عدم
            پشتیبانی مرورگر شما از این قابلیت باشد، که در مورد دوم پیشنهاد ما
            استفاده از آخرین نسخه ی مرورگر کروم (Chrome) است.
          </Text>

          <Heading fontSize="lg" mt=".5rem">
            بررسی وضعیت حضور در پویش
          </Heading>
          <Text textAlign="justify">
            در انتهای این صفحه، لیستی از روزهای پویش نمایش داده می شود، که
            بیانگر وضعیت حضور شما در پویش می باشد. در این لیست روزهایی که حضور
            شما ثبت شده باشد به رنگ سبز، روزهایی که شرکت نکرده اید به رنگ نارنجی
            و روزهای باقیمانده هم به رنگ خاکستری در می آیند. در پایین این لیست
            هم تعداد روزها براساس وضعیت آنها (حضور یافته، عدم حضور و باقیمانده)
            مشخص شده است.
          </Text>

          {/* <Heading fontSize="lg" mt=".5rem">
              بستن چله فعلی
            </Heading>
            <Text textAlign="justify">
              اگر به هر دلیلی امکان ثبت حضور مرتب در اپلیکشن برای شما فراهم نشد،
              می توانید چله مورد نظر خود را ببندید و چله جدید دیگری را آغاز
              کنید.{" "}
              <strong style={{ color: "var(--chakra-colors-red-500)" }}>
                دقت داشته باشید که اطلاعات چله قبلی از بین نخواهد رفت!
              </strong>
            </Text> */}
        </Flex>
      </PageHeader>
      <audio
        ref={audioRef}
        src="/audio/salavat.mp3"
        onEnded={() => setIsPlaying(false)}
      />
      <Card padding={"1rem"} marginBottom={5}>
        {true ? (
          <Box position={"relative"}>
            <JoinEventButton
              eventInfo={isFinish ? null : eventInfo}
              eventType={"count_based_event"}
              eventId={eventId}
              isDisabled={getEventQuery.data?.event.is_over}
              isFinish={isFinish}
              isArchived={eventInfo?.eventData.is_archived}
              isLoading={participationMutation.isLoading}
              onButtonClick={onButtonClick}
              tooltipText={
                !showCustomInput
                  ? getEventQuery.data?.event.is_over
                    ? "متاسفانه پویش تمام شده است"
                    : "برای شمارش لمس کنید"
                  : "تعداد دلخواه را وارد کرده و دکمه ثبت را بزنید"
              }
            />
            {showCustomInput && (
              <Flex
                position={"fixed"}
                flexDirection={"column"}
                top={0}
                bottom={0}
                right={0}
                left={0}
                zIndex={100}
                backdropFilter={"blur(8px)"}
                gap={2}
                justifyContent={"center"}
                alignItems={"center"}
                onClick={handleOutsideClick}
                className="modal-container"
                padding={5}
              >
                <Box onClick={(e) => e.stopPropagation()}>
                  <NumberInputPicker
                    title={""}
                    onChange={(v) => {
                      if (+v > 100) {
                        showToast({
                          title: "عدد وارد شده بزرگ است!",
                          description: "امکان افزودن بیش از ۱۰۰ وجود ندارد",
                          id: "MAX_NUMBER",
                          status: "warning",
                        });
                        v = "100";
                        setCustomCountErrorState(true);
                      }
                      setCustomCount(+v);
                    }}
                    //@ts-ignore
                    min={1}
                    isLoading={participationMutation.isLoading}
                    inputBgColor="#ffffffaa"
                    max={100}
                    onBlur={() => setCustomCountErrorState(false)}
                    placeHolder="تعداد مورد نظر را وارد نمایید"
                  />
                </Box>
                {customCountHasError ? (
                  <Tag onClick={(e) => e.stopPropagation()} colorScheme="red">
                    امکان افزودن بیش از ۱۰۰ وجود ندارد.
                  </Tag>
                ) : null}
                <Flex
                  onClick={(e) => e.stopPropagation()}
                  gap={2}
                  w={"100%"}
                  maxW={"450px"}
                >
                  <Button
                    mt={2}
                    flex={1}
                    colorScheme="telegram"
                    disabled={!count}
                    isLoading={participationMutation.isLoading}
                    onClick={() =>
                      customCount && participationMutation.mutate(customCount)
                    }
                  >
                    ثبت تعداد وارد شده
                  </Button>
                  <Button
                    mt={2}
                    colorScheme="red"
                    p={5}
                    onClick={() => {
                      setShowCustomInput(false);
                      setCustomCount(0);
                    }}
                  >
                    بستن
                  </Button>
                </Flex>
              </Flex>
            )}
          </Box>
        ) : (
          <Text textAlign={"center"}>پویش تکمیل شده است</Text>
        )}
        <ShareEventCard
          eventId={eventId}
          isOwner={getEventQuery.data?.event.is_owner}
          publicationState={getEventQuery.data?.event.publication_state}
          styles={{ mb: "1.5rem" }}
        />

        <Skeleton isLoaded={!getEventQuery.isLoading}>
          {!isFinish && !notStart && (
            <Flex marginBottom={5}>
              <Button
                mt={4}
                colorScheme="telegram"
                m="auto"
                onClick={() => {
                  setShowCustomInput((custom) => !custom);
                }}
                padding="0 50px"
              >
                افزودن تعداد دلخواه
              </Button>
            </Flex>
          )}
        </Skeleton>
      </Card>

      <Skeleton isLoaded={!getEventQuery.isLoading}>
        {eventInfo !== null &&
        eventInfo.untilStart <= 0 &&
        !(getEventQuery.isLoading || getEventQuery.isFetching) ? (
          <Card padding={5}>
            <Text as="h4" size="sm">
              آمار شما در پویش
            </Text>
            <Flex justifyContent={{ base: "start", md: "center" }}>
              <CountEventHeader
                isLoading={participationMutation.isLoading}
                participants={{
                  count: counted,
                  color: "green",
                  text: "تعداد",
                  hasBadge: true,
                }}
                isFinish={isFinish}
              />
            </Flex>
            <Text as="h4" size="sm">
              آمار مشارکت
            </Text>
            <Flex
              gap={2}
              flexWrap={"wrap"}
              justifyContent={{ base: "start", md: "center" }}
              marginBottom={2}
            >
              <CountEventHeader
                isLoading={participationMutation.isLoading}
                participants={{
                  count: parseInt(participated_users_count),
                  color: "green",
                  text: "تعدادکاربران",
                  hasBadge: true,
                }}
                isFinish={isFinish}
              />
              <CountEventHeader
                isLoading={participationMutation.isLoading}
                participants={{
                  count: total_participations_count + total_compensations_count,
                  color: "gray",
                  text: "تعداد کل",
                }}
                isFinish
              />
            </Flex>
            <Text as="h4" size="sm">
              آمار کل پویش
            </Text>
            <Flex
              gap={2}
              flexWrap={"wrap"}
              justifyContent={{ base: "start", md: "center" }}
            >
              <CountEventHeader
                isLoading={participationMutation.isLoading}
                participants={{
                  count:
                    target -
                    total_participations_count -
                    total_compensations_count,
                  color: "red",
                  text: "مانده",
                  hasBadge: true,
                }}
                isFinish
              />
              <CountEventHeader
                isLoading={participationMutation.isLoading}
                participants={{
                  count: target,
                  color: "gray",
                  text: "هدف",
                }}
                isFinish
              />
            </Flex>
          </Card>
        ) : null}
        <Stack
          direction="row"
          mt={8}
          justifyContent={"center"}
          alignItems={"center"}
          p={2}
        >
          <Heading as="h1" size="sm">
            توضیحات پویش
          </Heading>
          <Divider borderStyle={"dashed"} width={"auto"} flex={1} mr={2} />
        </Stack>

        <Text my={4} px={2}>
          {eventInfo?.eventData?.description}
        </Text>
        {!getEventQuery.data?.isPublicEvent && <Box height={"64px"} />}
      </Skeleton>

      {(getEventQuery.data ? getEventQuery.data.isPublicEvent : false) ? (
        <CommentsList
          //@ts-ignore
          eventId={eventId}
          mainVStackProps={{ mb: { base: "5rem", lg: "0" } }}
        />
      ) : null}
    </ScaleFade>
  );
}
