import {
  Box,
  Button,
  Flex,
  HStack,
  StackProps,
  Text,
  VStack,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Icon,
} from "@chakra-ui/react";
import { useEffect, useMemo, useState } from "react";
import WeekCalendar from "../MiniCalendar/WeekCalendar/WeekCalendar";
import { useQuery } from "react-query";
import { useAuth } from "../../hooks/useAuthContext";
import { FaRegSquareCheck } from "react-icons/fa6";

interface Data {
  counts: number;
  day: string;
  dayIndex: number;
  dateIndex: number;
}

interface PropDate {
  [key: string]: number;
}

interface ActivityChartNewProps {
  data: PropDate;
  description?: string;
  styles?: StackProps;
  isInHomePage?: boolean;
}

interface Date {
  startDate: string;
  endDate: string;
}

interface CurrentDayData {
  title: string;
  time: string;
}

export default function ActivityChartNew(props: ActivityChartNewProps) {
  const { authorizedAxios } = useAuth();

  const [hoveredDot, setHoveredDot] = useState<Data>();
  const [date, setDate] = useState<Date>({
    startDate: "",
    endDate: "",
  });

  const [numberOfShow, setNumberOfShow] = useState<number>(6);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isGetUserActivity, setIsGetUserActivity] = useState(false);
  const [currentDayData, setCurrentDayData] = useState<CurrentDayData[]>([]);
  const [currentDate, setCurrentDate] = useState("");
  const [forceRefetch, setForceRefetch] = useState(true);

  const [year, setYear] = useState<Data[][]>([[]]);

  const addLeadingZero = (str: string): string =>
    str.length === 1 ? "۰" + str : str;

  const formatPersianDate = (date: string): string => {
    const [year, month, day] = date.split("/");
    return `${year}/${addLeadingZero(month)}/${addLeadingZero(day)}`;
  };

  const convertDateToMoon = (dayArray: [string, number][]): Data[][] => {
    let dateIndex = 0;
    return dayArray.reduce((acc: Data[][], [date, value]) => {
      const persianDate = new Date(date).toLocaleDateString("fa", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
      const [year, month] = persianDate.split("/");

      const monthIndex =
        Number(
          (year + month).replace(/[۰-۹]/g, (char) =>
            String("۰۱۲۳۴۵۶۷۸۹".indexOf(char))
          )
        ) - 1;
      const GregorianDayIndex = new Date(date).getDay();
      const dayIndex = GregorianDayIndex === 6 ? 0 : GregorianDayIndex + 1;

      acc[monthIndex] = acc[monthIndex] || [];
      acc[monthIndex].push({
        day: formatPersianDate(persianDate),
        counts: value,
        dayIndex,
        dateIndex,
      });
      dateIndex++;
      return acc;
    }, []);
  };

  const convertMoonToWeek = (moons: Data[]) => {
    if (moons.length === 0) return [];

    let weekIndex = 6;
    let weekArray: Data[] = [],
      weeksArray: Data[][] = [];

    for (let i = 0; i < 7; i++) {
      weekArray[i] = { counts: 0, day: "", dayIndex: -1, dateIndex: 0 };
    }

    moons = props.isInHomePage ? [...moons].reverse() : [...moons];

    for (let i = 0; i < moons.length; i++) {
      if (moons[i].dayIndex <= weekIndex) {
        weekArray[moons[i].dayIndex] = moons[i];
      }

      if (moons[i].dayIndex === weekIndex || i === moons.length - 1) {
        weeksArray.push(weekArray);
        const lastDayOfMoon = [...weekArray]
          .reverse()
          .find((obj) => obj.day)
          ?.day?.split("/")[2]
          .replace(/[۰-۹]/g, (char) => String("۰۱۲۳۴۵۶۷۸۹".indexOf(char)));
        weekArray = [];
        for (let i = 0; i < 7; i++) {
          weekArray[i] = {
            counts: 0,
            day: "",
            dayIndex: lastDayOfMoon && +lastDayOfMoon > 23 ? -1 : 0,
            dateIndex: 0,
          };
        }
      }
    }

    const addEmptyWeeks = (start: number, end: number, isAddToEnd: boolean) => {
      weekArray = [];
      for (let i = 0; i < 7; i++) {
        weekArray[i] = { counts: 0, day: "", dayIndex: -1, dateIndex: 0 };
      }

      for (let i = start; i < end; i++) {
        if (isAddToEnd) {
          weeksArray.push(weekArray);
        } else {
          weeksArray.unshift(weekArray);
        }
      }
    };

    if (weeksArray.length < 6) {
      const moonFirstDay = moons[0]?.day?.split("/")[2];
      if (moonFirstDay === "۰۱") {
        addEmptyWeeks(weeksArray.length - 1, 5, true);
      } else {
        addEmptyWeeks(weeksArray.length - 1, 5, false);
      }
    }

    return weeksArray;
  };

  const handleHoveredDot = (day: Data) => {
    if (!props.isInHomePage) {
      console.log("day=", day);
      return;
    }
    if (day.dayIndex === -1) {
      setCurrentDate("");
      setHoveredDot(undefined);
      return;
    }
    setCurrentDayData([]);
    setForceRefetch(true);
    setHoveredDot(day);
  };

  const getCurrentDayUserActivityQuery = useQuery({
    queryKey: ["getCurrentDayUserActivityQuery"],
    retry: false,
    cacheTime: 0,
    staleTime: Infinity,
    enabled: isGetUserActivity,
    queryFn: async () => {
      const res = await authorizedAxios.get(
        `/user/activity_details/${hoveredDot?.day && Object.keys(props?.data)[hoveredDot.dateIndex]
        }/`
      );
      const data = res.data.data;
      return data;
    },
  });

  const details = useMemo(() => {
    let counts = 0;
    let minCounts = 0;
    let maxCounts = 0;

    Object.values(props?.data)?.forEach((value: number) => {
      counts += value;
      if (value > maxCounts) {
        maxCounts = value;
      }
      if (value < minCounts) {
        minCounts = value;
      }
    });

    return {
      description:
        props.description?.replace("{{count}}", `${counts}`) ??
        `تعداد کل فعالیت های انجام شده: ${counts}`,
      counts,
      minCounts,
      maxCounts,
    };
  }, [props.description, props?.data]);

  useEffect(() => {
    if (!props?.data) return;
    if (Object.keys(props?.data)?.length > 0) {
      const endDateIndex = Object.keys(props?.data).length;
      const endDate = Object.keys(props?.data)[0];
      const startDate = Object.keys(props?.data)[endDateIndex - 1];
      setDate({
        startDate,
        endDate,
      });
    }
    const moonArray = convertDateToMoon(Object.entries(props?.data));
    const yearArray = props.isInHomePage
      ? Object.values(moonArray).reverse()
      : Object.values(moonArray);
    setYear(yearArray);
  }, [props?.data]);

  useEffect(() => {
    if (!forceRefetch) return;
    if (hoveredDot) {
      setCurrentDate(Object.keys(props?.data)[hoveredDot.dateIndex]);
      setIsGetUserActivity(true);
      getCurrentDayUserActivityQuery.refetch();
      setForceRefetch(false);
    }
  }, [hoveredDot, forceRefetch]);

  useEffect(() => {
    if (currentDate) {
      if (!getCurrentDayUserActivityQuery.isLoading) {
        // console.log(
        //   "getCurrentDayUserActivityQuery.data==",
        //   getCurrentDayUserActivityQuery
        // );
        if (Array.isArray(getCurrentDayUserActivityQuery?.data)) {
          let uniqueItems: any;
          uniqueItems = getCurrentDayUserActivityQuery?.data?.reduce((acc, item) => {
            const existingItem = acc.find(i => i.title === item.title);
            if (existingItem) {
              existingItem.count = (existingItem.count || 1) + 1;
            } else {
              acc.push({ ...item, count: 1 });
            }
            return acc;
          }, []);
          setCurrentDayData(uniqueItems);
        } else {
          setCurrentDayData([getCurrentDayUserActivityQuery?.data]);
        }
        setIsOpen(true);
      }
    }
  }, [
    currentDate,
    getCurrentDayUserActivityQuery.isLoading,
    getCurrentDayUserActivityQuery?.data,
    forceRefetch,
  ]);

  useEffect(() => {
    if (!isOpen) {
      setCurrentDayData([]);
    }
  }, [isOpen]);

  const moons = {
    "۰۱": "فروردین",
    "۰۲": "اردیبهشت",
    "۰۳": "خرداد",
    "۰۴": "تیر",
    "۰۵": "مرداد",
    "۰۶": "شهریور",
    "۰۷": "مهر",
    "۰۸": "آبان",
    "۰۹": "آذر",
    "۱۰": "دی",
    "۱۱": "بهمن",
    "۱۲": "اسفند",
  };

  const dayNames = ["ش", "ی", "د", "س", "چ", "پ", "ج"];

  const sentencesTitles = [
    "الحمدلله در مسیر یاری امام زمان (عج) هستید 🥰",
    "اللهم بارک لمولانا صاحب الزمان (عج) 🤲",
    "ان شاالله اسم شما در زمره یاوران آقا ثبت بشه 🙂",
    "دعای حضرت زهراء سلام الله علیها بدرقه زندگی تون 🤲",
    "در پناه امام زمان (عج) باشید 🌼😊",
    "چقدر عالی 😍",
  ];

  const getRandomSentence = () => {
    const randomIndex = Math.floor(Math.random() * sentencesTitles.length);
    return sentencesTitles[randomIndex];
  };

  return (
    <>
      <Drawer
        size="lg"
        placement="bottom"
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
        onCloseComplete={() => setCurrentDayData([])}
      >
        <DrawerOverlay />
        <DrawerContent minH="50%">
          <DrawerCloseButton border="1px solid red" color="red.500" />
          <DrawerHeader marginBottom="0.5rem"></DrawerHeader>
          <DrawerBody
            display="flex"
            flexDirection="column"
            border="1px solid #00b0ff"
            borderRadius="4px"
            margin="0.8rem"
            padding="0.5rem"
          >
            {currentDayData.length ? (
              <>
                <Text marginBottom="1rem" fontWeight={600}>
                  {getRandomSentence()}
                </Text>
                <Text marginBottom="1rem" fontWeight={600}>
                  شما در تاریخ {hoveredDot?.day} برای زمینه سازی ظهور آقا قد
                  {currentDayData.length > 1 ? "مهایی" : "می"} برداشته اید
                </Text>
                <Flex direction="column" gap={2} flexGrow={1}>
                  {currentDayData.map((item: any, index) => (
                    <HStack
                      key={index}
                      marginBottom="0.5rem"
                    >
                      <Flex alignItems="center" gap={1}>
                        <Icon
                          as={FaRegSquareCheck}
                          w={5}
                          h={5}
                          color="green.500"
                        />{" "}
                        <span>عنوان: مشارکت در {item.title} <strong>{item?.count?.toLocaleString('fa')} مرتبه</strong></span>
                      </Flex>
                    </HStack>
                  ))}
                </Flex>
              </>
            ) : hoveredDot?.day && hoveredDot?.day < "۱۴۰۳/۰۹/۰۵" ? (
              <Text>
                گزارش فعالیت های روزانه در تقویم از تاریخ  ۵ آذر ماه ۱۴۰۳ به بعد
                موجود میباشد
              </Text>
            ) : (
              <Text>
                میدونم که زندگی تو وقف امام زمان عجل الله تعالی فرجه کردی اما
                متأسفانه برای شما در تاریخ {hoveredDot?.day} فعالیتی ثبت نشده
                است
              </Text>
            )}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      <VStack spacing=".75rem" alignItems="stretch" {...props.styles}>
        {props.isInHomePage && (
          <HStack>
            <Button
              fontSize="sm"
              onClick={() => setNumberOfShow(1)}
              flex={1}
              background={
                numberOfShow === 1 ? "var(--chakra-colors-gray-200)" : "none"
              }
            >
              یک ماهه
            </Button>
            <Button
              fontSize="sm"
              onClick={() => setNumberOfShow(6)}
              flex={1}
              background={
                numberOfShow === 6 ? "var(--chakra-colors-gray-200)" : "none"
              }
            >
              شش ماهه
            </Button>
            <Button
              fontSize="sm"
              onClick={() => setNumberOfShow(13)}
              flex={1}
              background={
                numberOfShow === 13 ? "var(--chakra-colors-gray-200)" : "none"
              }
            >
              یک ساله
            </Button>
          </HStack>
        )}
        {Object.keys(props?.data)?.length > 0 && props.isInHomePage ? (
          <Text textAlign="start" fontSize="xs">
            از تاریخ {new Date(date.startDate).toLocaleDateString("fa")} تا{" "}
            {new Date(date.endDate).toLocaleDateString("fa")}
          </Text>
        ) : null}

        <Flex gap="0.2rem" flexWrap="wrap" justifyContent="space-around">
          {year?.map((moon, moonIndex) => {
            if (moonIndex >= numberOfShow) return;
            return (
              <Flex key={moonIndex} gap="0.1rem" alignItems="flex-start">
                <Box
                  style={{
                    writingMode: "vertical-rl",
                    textOrientation: "mixed",
                  }}
                  flex={1}
                  fontSize="xs"
                  height={140}
                  textAlign="center"
                >
                  {moons[moon[0]?.day?.split("/")?.[1]]}
                </Box>
                <Flex flexDirection="column" gap="0.3rem">
                  <Flex gap="0.3rem">
                    {dayNames.map((day) => (
                      <Box
                        key={day}
                        w="1rem"
                        h="1rem"
                        fontSize="xs"
                        textAlign="center"
                      >
                        {day}
                      </Box>
                    ))}
                  </Flex>
                  {convertMoonToWeek(moon)?.map((week, weekIndex) => (
                    <Flex key={weekIndex} gap=".3rem">
                      {week.map((day, dayIndex) => {
                        const currentDay = new Date().toLocaleDateString("fa", {
                          year: "numeric",
                          month: "2-digit",
                          day: "2-digit",
                        });
                        const dayNumber = day?.day;
                        let isCurrentDay = currentDay === dayNumber;
                        return (
                          <Box
                            key={dayIndex}
                            w="1rem"
                            h="1rem"
                            bgColor={
                              isCurrentDay
                                ? "red.400"
                                : day.counts > 0
                                  ? "var(--c--success)"
                                  : day.dayIndex === -1
                                    ? "transparent"
                                    : "var(--c--outline)"
                            }
                            opacity={
                              day.counts > 0
                                ? day.counts / details.maxCounts + 0.36
                                : 1
                            }
                            onClick={() => handleHoveredDot(day)}
                            cursor="pointer"
                            fontSize="9px"
                            textAlign="center"
                            lineHeight="16px"
                            color={day.counts > 0 ? "white" : "gray.500"}
                            borderRadius="3px"
                            animation={
                              isCurrentDay
                                ? "pulse_sm_green_anim_half 1s ease-out infinite"
                                : ""
                            }
                            border={
                              isCurrentDay
                                ? "1px solid green"
                                : day.dayIndex === -1
                                  ? "1px solid lightgray"
                                  : "none"
                            }
                          >
                            {dayNumber.split("/")[2]}
                          </Box>
                        );
                      })}
                    </Flex>
                  ))}
                </Flex>
              </Flex>
            );
          })}
        </Flex>
        {props.isInHomePage && (
          <HStack>
            {hoveredDot ? (
              <Box
                w=".75rem"
                h=".75rem"
                borderRadius="sm"
                cursor="pointer"
                bgColor={
                  hoveredDot.counts > 0
                    ? "var(--c--success)"
                    : "var(--c--outline)"
                }
                opacity={
                  hoveredDot.counts > 0
                    ? hoveredDot.counts / details.maxCounts + 0.4
                    : 1
                }
              />
            ) : null}
            <Text flex="1" textAlign="start" fontSize="xs">
              {hoveredDot
                ? `${hoveredDot.counts} فعالیت در ${hoveredDot.day} ثبت شده است.`
                : details.description}
            </Text>
          </HStack>
        )}
      </VStack>
    </>
  );
}
